let ejs = require('ejs')
import report from '@/services/report'

import pVoucher from 'raw-loader!../views/template/pVoucher.ejs'
import allVoucher from 'raw-loader!../views/template/allVoucher.ejs'
import poster from 'raw-loader!../views/template/poster.ejs'

export default {
  exportVoucher(data) {
    try {
      return ejs.render(pVoucher, data)
    } catch (e) {
      console.error(e)
    }
  },

  printAllVoucher(data) {
    try {
      return ejs.render(allVoucher, data)
    } catch (e) {
      console.error(e)
    }
  },

  printPDF(name, data) {
    let uid = data.uid

    report({
      method: 'post',
      url: '/print/v1.0/' + uid + '/htmltopdf/a4',
      data: data,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
    })
  },

  exportPoster(data) {
    try {
      return ejs.render(poster, data)
    } catch (e) {
      console.error(e)
    }
  },
}
